<template>
  <v-select
    :value="value"
    :options="options"
    :placeholder="loading ? 'Loading...' : ''"
    :reduce="item => item.value"
    :multiple="multiple"
    label="text"
    @input="onInput"
  />
</template>
<script>
import vSelect from 'vue-select'
import OrdersAPI from '@/api/order'

export default {
  components: {
    vSelect,
  },
  props: {
    value: [String, Array],
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      data: [],
      loading: true,
      defaultItem: this.value,
      options: [],
    }
  },
  computed: {
    products() {
      return this.data.length === 0 ? [] : this.data.map(item => ({
        value: item.id,
        text: `${item.platform} | ${item.account_type} | ${item.deposit / 1000}k ${item.currency.label} | price: $${item.price} ${item.deposit === 0 ? ' | Custom' : ''}`,
      }))
    },
  },
  mounted() {
    OrdersAPI.fetchAllProducts().then(response => {
      this.data = response
      this.options = this.products
      this.loading = false
    })
  },
  methods: {
    onInput(id) {
      this.$emit('input', id)
      const product = this.data.find(p => p.id === id)
      this.$emit('selected', product)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
